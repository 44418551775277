import React, {useState} from "react";
import Button from "react-bootstrap/Button";
import {Spinner} from "react-bootstrap";
import {Google} from "react-bootstrap-icons";
import {useAuth} from "../auth/AuthContext";

export function LoginWithGoogleButton() {
    const { loginWithGoogle } = useAuth();
    const [isLoading, setIsLoading] = useState(false);

    const handleGoogleLogin = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            await loginWithGoogle();
        } catch (err) {
            console.error("google login failed", err);
            setIsLoading(false);
        }
    }

    return (<Button
        onClick={handleGoogleLogin}
        variant="outline-primary"
        className="d-flex align-items-center justify-content-center gap-2"
    >
        {isLoading ? (
            <Spinner animation="border" size="sm"/>
        ) : (
            <Google/>
        )}
        {isLoading ? 'Redirecting...' : 'Log in with Google'}
    </Button>)
}