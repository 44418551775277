import { redirect } from 'react-router-dom';

export async function checkAuth() {
    const response = await fetch('/auth');
    return response.status === 204;
}

export async function redirectOnAuthenticatedLoader(redirectTo) {
    const isAuthenticated = await checkAuth();
    if (isAuthenticated) {
        return redirect(redirectTo || '/games');
    }
    return null;
}

export function protectedLoader(loaderFn) {
    return async ({ request, params }) => {
        const isAuthenticated = await checkAuth();
        if (!isAuthenticated) {
            const params = new URLSearchParams();
            params.set("from", new URL(request.url).pathname)
            return redirect("/login?" + params.toString())
        }
        return loaderFn ? await loaderFn({ request, params }) : null;
    }
}