import {Form, useActionData, useLocation, useNavigate} from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FormControl from "react-bootstrap/FormControl";
import Stack from "react-bootstrap/Stack";
import Button from "react-bootstrap/Button";
import React, {useEffect} from "react";
import {useAuth} from "./auth/AuthContext";
import {LoginWithGoogleButton} from "./components/LoginWithGoogleButton";

export function LoginPage() {
    const { checkAuth } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const actionData = useActionData();
    let params = new URLSearchParams(location.search);
    let from = params.get("from") || "/games";

    // Use effect to handle successful login
    useEffect(() => {
        if (actionData?.success) {
            // Update auth context state
            checkAuth().then(() => {
                // Navigate to redirect path
                navigate(actionData.redirectTo || from)
            });
        }
    }, [actionData, checkAuth, navigate, from]);
    return (
        <Row className="login">
            <Col xs={10} sm={9} md={8} lg={4} className="mx-auto mt-5 p-5 shadow-sm">
                <Form method="post" replace>
                    <FormControl hidden={true} name="redirectTo" value={from}/>
                    <Stack direction="vertical" gap={3}>
                        <FormControl required={true} name="email" type="email" placeholder="email"/>
                        <FormControl required={true} name="password" type="password" placeholder="password"/>
                        <Button type="submit" variant="outline-success">Sign In</Button>
                        <LoginWithGoogleButton/>
                    </Stack>
                </Form>
                {actionData?.error && (<p className="alert-danger">{actionData.error}</p>)}
            </Col>
        </Row>
    );
}