import {createContext, useContext, useEffect, useState} from "react";
import {getHouse} from "../services/ledgerService";
import {checkAuth} from "./authUtils";

const AuthContext = createContext(null);

export function AuthProvider({ children }) {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [house, setHouse] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        verifyAuth().finally(() => {
            setIsLoading(false);
        })
    }, [])

    const loginWithGoogle = () => {
        window.location.href = "/auth/login"
    }
    const verifyAuth = async () => {
        try {
            const isAuth = await checkAuth();
            setIsAuthenticated(isAuth);

            if (isAuth) {
                const houseData = await getHouse();
                setHouse(houseData)
            }
        } catch (e) {
            setIsAuthenticated(false);
            setHouse(null);
        }
    }
    const logout = async () => {
        try {
            const response = await fetch("/logout", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
            });
            if (response.ok) {
                setIsAuthenticated(false);
                setHouse(null);
            } else {
                throw new Error('Logout failed');
            }
        } catch (e) {
            console.error('Logout error:', e);
            throw e;
        }
    }

    if (isLoading) {
        return <div>Loading...</div>
    }

    return (
        <AuthContext.Provider value={{
            isAuthenticated,
            logout,
            house,
            loginWithGoogle,
            checkAuth: verifyAuth,
        }}>
            {children}
        </AuthContext.Provider>
    )
}

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error("useAuth must be used within an AuthProvider");
    }
    return context;
}