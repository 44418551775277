import Stack from "react-bootstrap/Stack";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Image from "react-bootstrap/Image";
import React, {useState} from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import AddMemberForm from "./AddMemberForm";
import EditMemberForm from "./EditMemberForm";
import {useAuth} from "../../auth/AuthContext";

export default function Members() {
    const { house } = useAuth();

    const memberCards = house.members.map((m, i) => (
        <MemberCard key={i} member={m} />
    ));

    return (
        <Row>
            <Col xs={12} md={5} className="mb-2 order-md-2">
                {<AddMemberForm />}
            </Col>
            <Col xs={12} md={7} className="mx-auto mx-md-0">
                <Stack gap={3}>
                    {memberCards}
                </Stack>
            </Col>
        </Row>
    );
};

const MemberCard = ({ member }) => {
    const [editing, setEditing] = useState(false)

    const enableEditing = () =>  setEditing(true)
    const submitEdit = ()  => setEditing(false)

    const memberRow = editing
        ? <EditMemberForm member={member} onSubmit={submitEdit}></EditMemberForm>
        : <>
            <Col xs={2} sm={4}><Image src={`https://i.pravatar.cc/50?u=${member.id}`} roundedCircle></Image></Col>
            <Col xs={6} sm={4}>{member.name}</Col>
            <Col xs="auto" sm="auto" className="md-auto"><Button type='button' onClick={enableEditing}>Edit</Button></Col>
        </>;

    return (
        <Card className={"member-card"}>
            <Card.Body>
                    <Row className={"align-items-center justify-content-between"}>
                        {memberRow}
                    </Row>
            </Card.Body>
        </Card>
    );
}

