import {useNavigate} from "react-router-dom";
import React, {useEffect} from "react";
import {useAuth} from "./auth/AuthContext";

export function LogoutPage() {
    const { logout } = useAuth();
    const navigate = useNavigate();

    // Use effect to handle successful login
    useEffect(() => {
        const performLogout = async () => {
            await logout();
            navigate('/', { replace: true });
        }

        performLogout();
    }, [logout, navigate]);
    return (
        <div>Logging out...</div>
    );
}