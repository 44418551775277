import React from 'react';
import Button from "react-bootstrap/Button";
import Card from 'react-bootstrap/Card';
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Stack from "react-bootstrap/Stack";
import LinkContainer from 'react-router-bootstrap/LinkContainer'

import {createGame} from '../../services/ledgerService'
import {useForm} from "../../UseForm";

import {formatDate, formatMoney} from "../../utilities";

import './House.css'
import {useAuth} from "../../auth/AuthContext";

const House = () => {
    const { house } = useAuth();
    return (
        <>
            <Row>
                <Col className="d-flex py-1 py-sm-3 align-items-center"><h1>{house.description}</h1></Col>
            </Row>
            <Row className="create-game">
                <Col className="border-black rounded-3 bg-dark p-4 shadow-sm">
                    {CreateGameForm({ house })}
                </Col>
            </Row>
            <Row className="mt-3">
                <Col sm={10} className={'mx-auto'}>
                    {GameList({ games: house.games })}
                </Col>
            </Row>
        </>
    );
};

const GameList = ({ games }) => {
    if (!games.length) {
        return (
            <Card className="p-3">
                <div className="text-muted align-self-center">No games yet!</div>
            </Card>
        );
    }

    return (
        <Stack gap={3}>
        {games.map(GameSummary)}
        </Stack>
    );
}

const GameSummary = ({ id, description, date, totalBuyIns, totalCashOuts, totalNet }) => {
    const formattedDate = formatDate(date);
    return (
        <LinkContainer key={id} to={`/games/${id}`}>
            <Card className="game-summary p-3">
                <Row>
                    <Col><Card.Title>{description}</Card.Title></Col>
                    <Col className='text-end'><small className='text-muted'>{formattedDate}</small></Col>
                </Row>
                <Row className="details">
                    <Col>
                        <div>
                            <label>Buy Ins</label>
                            <span className='ps-1 money'>{formatMoney(totalBuyIns)}</span>
                        </div>
                    </Col>
                    <Col>
                        <div>
                            <label>Cash Outs</label>
                            <span className='ps-1 money'>{formatMoney(totalCashOuts)}</span>
                        </div>
                    </Col>
                    <Col>
                        <div>
                            <label>Net</label>
                            <span className={`ps-1 money fw-bold ${totalNet >= 0 ? 'text-success' : 'text-danger'}`}>{formatMoney(totalNet)}</span>
                        </div>
                    </Col>
                </Row>
            </Card>
        </LinkContainer>
    );
};

const CreateGameForm = ({ house }) => {
    const stateUpdater = (result) => { house.games.push(result) }
    const { handleSubmit, status, message } = useForm(createGame, stateUpdater)
    return (
        <Form onSubmit={handleSubmit}>
            <Stack gap={3}>
                <Row className="d-flex align-items-center justify-content-between">
                    <Col xs={8} className='d-sm-flex gap-3 justify-content-center'>
                        <div className='flex-grow-1' style={{ maxWidth: '20em' }}>
                            <Form.Control 
                                required={true} 
                                name="description" 
                                placeholder="Description..." 
                            />
                        </div>
                        <div className='mt-2 mt-sm-0' style={{ maxWidth: '20em' }}>
                            <Form.Control 
                                required={true} 
                                name="date" 
                                type="date" 
                                placeholder="Date..." 
                            />
                        </div>
                    </Col>
                    <Col xs={4} className="d-flex justify-content-center">
                        <div className="vr me-4 me-sm-5"></div>
                        <Button 
                            type="submit" 
                            variant="outline-success"
                        >
                            Create Game
                        </Button>
                    </Col>
                </Row>
                {/*{status && (*/}
                {/*    <Row>*/}
                {/*        <Col xs={12}>*/}
                {/*            <span>{status}: {message}</span>*/}
                {/*        </Col>*/}
                {/*    </Row>*/}
                {/*)}*/}
            </Stack>
        </Form>
    )
}

export default House;