import {createBrowserRouter, Form, RouterProvider, useActionData, useLocation} from "react-router-dom";
import RootLayout from "./RootLayout";
import ErrorPage from "./error-page";
import {Ledger, loader as gameLoader} from "./components/ledger";
import React from "react";

import {AuthProvider} from "./auth/AuthContext"
import Landing from "./components/landing/Landing";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Stack from "react-bootstrap/Stack";
import FormControl from "react-bootstrap/FormControl"
import Button from "react-bootstrap/Button";
import {House} from "./components/house";
import Members from "./components/member/Members";
import {action as membersAction} from "./components/member/AddMemberForm";
import {action as memberUpdateAction} from "./components/member/EditMemberForm";
import {protectedLoader, redirectOnAuthenticatedLoader} from "./auth/authUtils";
import {LoginPage} from "./LoginPage";
import {LoginWithGoogleButton} from "./components/LoginWithGoogleButton";
import {LogoutPage} from "./LogoutPage";

const router = createBrowserRouter([
    {
        id: "root",
        path: "/",
        Component: RootLayout,
        ErrorBoundary: ErrorPage,
        children: [
            {
                index: true,
                element: <Landing/>,
            },
            {
                path: "register",
                action: registerAction,
                loader: redirectOnAuthenticatedLoader,
                Component: RegisterPage,
            },
            {
                path: "login",
                action: loginAction,
                loader: redirectOnAuthenticatedLoader,
                Component: LoginPage,
            },
            {
                path: "logout",
                Component: LogoutPage,
            },
            {
                id: "house",
                path: "games",
                Component: House,
                loader: protectedLoader(() => null)
            },
            {
                path: "members",
                Component: Members,
                action: membersAction,
                loader: protectedLoader(() => null),
                children: [
                    {
                        path: ':memberId',
                        action: memberUpdateAction,
                    }
                ]
            },
            {
                path: "games/:gameId",
                Component: Ledger,
                loader: protectedLoader(gameLoader),
            },
        ]
    },
]);

async function loginAction({ request }) {
    const { email, password, redirectTo } = Object.fromEntries(await request.formData())

    if (!email || !password) {
        return { error: "You must provide username and password to log in" }
    }

    try {
        const response = await fetch('/login', {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ email, password })
        });
        if (!response.ok) {
            return { error: "Invalid login attempt"};
        }
        return { success: true, redirectTo: redirectTo || "/games" };
    } catch (err) {
        console.log("login failed...", err);
        return { error: "Invalid login attempt", }
    }
}

async function registerAction({ request }) {
    const { email, password, firstName, lastName, description, redirectTo } = Object.fromEntries(await request.formData())

    if (!email || !password || !description) {
        return {
            error: "You must provide username and password and description to register"
        }
    }

    try {
        const response = await fetch("/register", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                email,
                password,
                firstName,
                lastName,
                description,
            }),
        });

        if (response.status === 409) {
            return { error: `A user already exists with email: ${email}` };
        }
        if (!response.ok) {
            return { error: "Registration failed" };
        }
        return { success: true, redirectTo };
    } catch (err) {
        console.log("register failed...", err);
        return { error: "Registration failed" };
    }
}

function RegisterPage() {
    let location = useLocation();
    let params = new URLSearchParams(location.search);
    let from = params.get("from") || "/";

    let actionData = useActionData();

    return (
        <Row className="login">
            <Col xs={12} sm={6} className="mx-auto mt-5 p-5 shadow-sm">
                <Form method="post" replace >
                    <FormControl hidden={true} name="redirectTo" value={from} />
                    <Row className="pb-3">
                        <Col xs={6}>
                            <FormControl required={true} name="firstName" type="text" placeholder="first name" />
                        </Col>
                        <Col xs={6}>
                            <FormControl required={true} name="lastName" type="text" placeholder="last name" />
                        </Col>
                    </Row>
                    <Row>
                        <Stack direction="vertical" gap={3}>
                            <FormControl required={true} name="email" type="email" placeholder="email" />
                            <FormControl required={true} name="password" type="password" placeholder="password" />
                            <FormControl required={true} name="description" placeholder="provide your club title" />
                            <Button type="submit"  variant="outline-success">Register</Button>
                            <LoginWithGoogleButton />
                        </Stack>
                    </Row>
                    {actionData && actionData.error
                        ? <Row className="pt-3"><Col xs={12}><p className="text-danger">{actionData.error}</p></Col></Row>
                        : <></>
                    }
                </Form>
            </Col>
        </Row>
    );
}

export default function App() {
    return (
        <AuthProvider>
            <RouterProvider
                router={router}
                fallbackElement={<p>Initial Load...</p>}
            />
        </AuthProvider>
    );
}